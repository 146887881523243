import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer.component";
import { HeaderNameChange } from "../../components/header/HeaderNameChange.component";
import { FooterNameChange } from "../../components/footer/FooterNameChange.component";

export const LayoutHeader = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation();

    useEffect(() => {
        document.getElementById("testLink").href = window.location.href;
    }, [location.pathname])
    return (
        <>
            <HeaderNameChange />
            <Outlet />
            <FooterNameChange />
        </>
    );
};

