import React from 'react'
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

import './top-airlines.style.css'
import BannerSearchForm from '../../components/flight-search-form/BannerSearchForm.component'
import FlightSearchForm from '../../components/flight-search-form/FlightSearchForm.component'

export const PopAirlines = () => {
    return (
        <>
         <div className="home-banner">
            <div className="cm-page-center">
            <div className="search-banner">
                <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
                <FlightSearchForm />
            </div>
            </div>
        </div>
        <div className="cm-section cm-page-center">
            {/* <BannerSearchForm depVal={data} urlVal={dataToSend} /> */}
            <div className="ryan-air">
                <div className="ryanair-title">
                    <h1>Popular <span>Airlines</span></h1>
                </div>
                <div className="ryanair-content">
                    <p>
                        Popular Airlines are well-known and well-acknowledged air transportation providers that serve millions of customers globally. A combination of superior customer service, safety records, broad route networks, and customer contentment have helped the airlines to achieve their status. Moreover, all the famous Airlines primary aim is to offer passengers a seamless and enjoyable travel experience. They focus on ensuring safety and reliability, offering diverse destinations and routes, and maintaining high-quality customer service throughout the journey.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}
