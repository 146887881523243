import React from 'react'
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

import '../top-airlines/top-airlines.style.css'
import BannerSearchForm from '../../components/flight-search-form/BannerSearchForm.component'
import FlightSearchForm from '../../components/flight-search-form/FlightSearchForm.component'

export const Toronto = () => {
    return (
        <>
        <div className="home-banner">
            <div className="cm-page-center">
            <div className="search-banner">
                <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
                <FlightSearchForm />
            </div>
            </div>
        </div>
        <div className="cm-section cm-page-center">
            {/* <BannerSearchForm depVal={data} urlVal={dataToSend} /> */}
            <div className="ryan-air">
                <div className="ryanair-title">
                    <h1>Toronto</h1>
                </div>
                <div className="ryanair-content">
                    <p>
                        Canada's largest and most populated metropolis is Toronto. Occasionally mistaken for the nation's capital, Toronto provides a fantastic opportunity to discover Canada and some of its top attractions. The city has experienced significant growth over the past 10 years, with several new buildings rising along the northern shore of Lake Ontario. The city is perfect for solitary travelers, families, and backpackers since it offers a variety of activities both inside and outside the city, including museums, parks, and spectacular structures. It has been a top tourist destination. Hence if you are planning to visit, book your flights to Toronto now.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}
