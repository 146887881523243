import React from 'react'
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

import '../top-airlines/top-airlines.style.css'
import BannerSearchForm from '../../components/flight-search-form/BannerSearchForm.component'
import FlightSearchForm from '../../components/flight-search-form/FlightSearchForm.component'

export const Chicago = () => {
    return (
        <>
        <div className="home-banner">
            <div className="cm-page-center">
            <div className="search-banner">
                <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
                <FlightSearchForm />
            </div>
            </div>
        </div>
        <div className="cm-section cm-page-center">
            {/* <BannerSearchForm depVal={data} urlVal={dataToSend} /> */}
            <div className="ryan-air">
                <div className="ryanair-title">
                    <h1>Chicago</h1>
                </div>
                <div className="ryanair-content">
                    <p>
                        When you want to reserve flights to Chicago, Illinois, There are two airports that provide service to the windy city. O, Hare international airport is the primary one, while the secondary tarmac is at Chicago Midway International Airport. O'Hare is located 17 miles to the northwest of the loop and offers a wide range of entertainment options while you wait for your flight or other mode of transit into Chicago. Also, this airport has numerous dining options, including sit-down restaurants and quick-service cafes. Midway Airport also includes several shops, most located in the midway boulevard.
                    </p>
                    <p>
                        Plan your activities once you've determined it's time to get airline tickets to Chicago, Illinois. From the galleries of the Museum of Contemporary Art to the antiquities at the Chicago History Museum, the Windy City is home to fascinating displays.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}
