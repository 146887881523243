import React, { useState } from "react"
import { Link } from "react-router-dom";

import logo from "../../assets/images/flight/logo-new.png";
import { phoneNum } from "../../utils/globalVars";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CountrySelector from "../countrySeloctor/CountrySelector"


import "./Footer.styles.css";
import { useEffect } from "react";


export const FooterNameChange = () => {

    const [isActive, setActive] = useState(false)
    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleExpClick = (origin, dest) => {
        navigate({
            pathname: "/flights",
            search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
                "YYYY-MM-DD"
            )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
        });
    };

    const { pathname } = window.location;
    const [newClass, setNewClass] = useState("");
    useEffect(() => {
        if (pathname === "/name-change/united-airlines-name-change") {
            setNewClass("united-footer")
        }
    }, [pathname])

    return (
        <footer className={`cm-footer-container ${newClass}`}>
            <div className={`cm-fixed-footer-cta cm-txt-center cm-white-bg box-shadow-2 cm-wt-600 ${newClass}`}>
                <p>
                    Save Instantly on Unpublished Deals - CALL NOW<br></br>{" "}
                    <a href="tel:+1-866-738-0741" className="cm-white-col">
                        <i className="fa-solid fa-phone-volume"></i>
                        +1-866-738-0741
                    </a>
                </p>
            </div>
            <div className="cm-footer-top">
                <div className="cm-page-center cm-flex-type-2 cm-flex-align-fs">
                    {/* <div className="cm-footer-col footer-logo cm-wd-25">
                        <Link to="/"><img src={logo} alt="" /></Link>
                        <p>FlightHubDeal is an independent travel website that provides a plethora of different locations where you can have your ideal vacation. We also ensure that you find the best flights within your desired timetable and price. At FlightHubDeal, our staff is dedicated to enhancing your travel experience by offering outstanding deals on flight booking, flight cancellations, flight changes, and name correction. </p>
                    </div> */}
                    <div className="cm-footer-col cm-wd-33">
                        <h3>Terms</h3>
                        <ul className="cm-menu-ul">
                            <li>
                                <Link to="#">About Us</Link>
                            </li>
                            <li>
                                <Link to="#">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="#">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="#">Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to="#">Disclaimer</Link>
                            </li>
                            <li>
                                <Link to="#">Refund and Cancellation Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="cm-footer-col cm-wd-33">
                        <h3>Name Change</h3>
                        <ul className="cm-menu-ul">
                            <li>
                                <Link to="/name-change/united-airlines-name-change">United Airlines</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="cm-footer-col cm-wd-33 ">
                        <h3>Reach us at</h3>
                        <ul className="cm-menu-ul icon-align">
                            <li>
                                <a href="tel:+1-866-738-0741">
                                    <i className="fa-solid fa-phone"></i>
                                    <span>+1-866-738-0741</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

