import React from 'react'
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

import '../top-airlines/top-airlines.style.css'
import BannerSearchForm from '../../components/flight-search-form/BannerSearchForm.component'
import FlightSearchForm from '../../components/flight-search-form/FlightSearchForm.component'

export const TopDestinations = () => {
    return (
        <>
        <div className="home-banner">
            <div className="cm-page-center">
            <div className="search-banner">
                <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
                <FlightSearchForm />
            </div>
            </div>
        </div>
        <div className="cm-section cm-page-center">
            {/* <BannerSearchForm depVal={data} urlVal={dataToSend} /> */}
            <div className="ryan-air">
                <div className="ryanair-title">
                    <h1>Top <span>Destinations</span></h1>
                </div>
                <div className="ryanair-content">
                    <p>
                        Popular travel locations are those that are well-liked or in high demand and draw a substantial number of tourists. In addition to their cultural significance, these locations are well-known for their historical landmarks, scenic beauty, and unique activities. The top places may alter according on the year, trends, and individual preferences. If you're a solo traveler or planning a trip with family, pack your bags and head to your preferred location because there are innumerable and much more amazing places to see in the world.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}
