import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer.component";
import Header from "../../components/header/Header.component";

const Layout = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    document.getElementById("testLink").href = window.location.href;
  }, [location.pathname])
  return (
    <>
      <Header />
      {/* {props.children} */}
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
