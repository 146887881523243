import React from 'react'
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

import '../top-airlines/top-airlines.style.css'
import BannerSearchForm from '../../components/flight-search-form/BannerSearchForm.component'
import FlightSearchForm from '../../components/flight-search-form/FlightSearchForm.component'

export const Cancun = () => {
    return (
        <>
        <div className="home-banner">
            <div className="cm-page-center">
            <div className="search-banner">
                <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
                <FlightSearchForm />
            </div>
            </div>
        </div>
        <div className="cm-section cm-page-center">
            {/* <BannerSearchForm depVal={data} urlVal={dataToSend} /> */}
            <div className="ryan-air">
                <div className="ryanair-title">
                    <h1>Cancun</h1>
                </div>
                <div className="ryanair-content">
                    <p>
                        Are you trying to arrange the ideal Cancun getaway? Millions of tourists from all over the world visit Cancun each year, making it one of Mexico's most prominent and most well-liked holiday destinations. Cancun is the best Caribbean destination in Mexico because of its beaches, ocean, culture, and nightlife, to name a few things. Make a start on your ideal getaway today! Get on flights to Cancun and see the breathtaking scenery and fantastic beaches. Cancun, like many other cities in the Mexican Caribbean, was once a major Mayan cultural hub. For the Mayan fishing and seafaring commerce, this specific area of land was crucial.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}
