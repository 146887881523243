import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
// import moment from "moment";
import "./Homepage.styles.css";
// import { useNavigate } from "react-router-dom";
// import callMob from "../../assets/images/flight/book-tick.png";
import { phoneNum } from "../../utils/globalVars";
// import { useState } from "react";
import SEO from "../../utils/SEO";
import BestTour from "../../components/BestTours/BestTour";
import BestTourAu from "../../components/BestTours/BestTourAu";
import BestTourCa from "../../components/BestTours/BestTourCa";
import BestTourIn from "../../components/BestTours/BestTourIn";
import BestTourNz from "../../components/BestTours/BestTourNz";
import BestTourPh from "../../components/BestTours/BestTourPh";
import BestTourQa from "../../components/BestTours/BestTourQa";
import BestTourRp from "../../components/BestTours/BestTourRp";
import BestTourSg from "../../components/BestTours/BestTourSg";
import BestTourUAE from "../../components/BestTours/BestTourUAE";
import BestTourHk from "../../components/BestTours/BestTourHk";
import BestTourMy from "../../components/BestTours/BestTourMy";
import BestTourZa from "../../components/BestTours/BestTourZa";
import BestTourTh from "../../components/BestTours/BestTourTh";
import BestTourUk from "../../components/BestTours/BestTourUk";
import BestTourVn from "../../components/BestTours/BestTourVn";


const HomePage = () => {

  // const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleExpClick = (origin, dest) => {
  //   navigate({
  //     pathname: "/flights",
  //     search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
  //       "YYYY-MM-DD"
  //     )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
  //   });
  // };

  // const [showPopup, setPopup] = useState('active')

  // function removePopup(){
  //   setPopup('')
  // }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  // const offersettings = {
  //   dots: true,
  //   arrows: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   centerMode: true,
  //   centerPadding: '100px',
  //   adaptiveHeight: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         centerPadding: '70px',
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: '50px',
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: '0px',
  //       }
  //     }
  //   ]
  // };

  const loadBestTour = () => {
    let urlname
    urlname = window.location.hostname.split('.')[0];
    if (urlname === 'localhost') {
      urlname = "au"
    } else {
      if (urlname === 'www') {
        urlname = "au"
      }
    }

    return (
      <>
        {urlname === 'flighthubdeal' ? <BestTour /> : ""}
        {urlname === 'au' ? <BestTourAu /> : ""}
        {urlname === 'ca' ? <BestTourCa /> : ""}
        {urlname === 'hk' ? <BestTourHk /> : ""}
        {urlname === 'in' ? <BestTourIn /> : ""}
        {urlname === 'id' ? <BestTourRp /> : ""}
        {urlname === 'my' ? <BestTourMy /> : ""}
        {urlname === 'nz' ? <BestTourNz /> : ""}
        {urlname === 'ph' ? <BestTourPh /> : ""}
        {urlname === 'qa' ? <BestTourQa /> : ""}
        {urlname === 'sg' ? <BestTourSg /> : ""}
        {urlname === 'sa' ? <BestTourZa /> : ""}
        {urlname === 'th' ? <BestTourTh /> : ""}
        {urlname === 'en' ? <BestTour /> : ""}
        {urlname === 'es' ? <BestTour /> : ""}
        {urlname === 'ae' ? <BestTourUAE /> : ""}
        {urlname === 'uk' ? <BestTourUk /> : ""}
        {urlname === 'vn' ? <BestTourVn /> : ""}
      </>
    )
  }
  return (
    <>
      <SEO
        title="FlightHubDeal -Ultimate Destination for Cheap Flight Booking"
        description="FlightHubDeal - Your ultimate destination for hassle-free travel bookings. Find the best deals on flights, and embark on your dream journey today. Visit us"
      />

      <div className="home-banner">
        <div className="cm-page-center">
          <div className="search-banner">
            <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
            <FlightSearchForm />
          </div>
        </div>
      </div>

      <div className="add-section">
        <div className="cm-page-center">
          <div className="cm-flex cm-flex-wrap">
            <div className="cm-wd-50">
              <div className="column promo-col cm-flex cm-flex-wrap">
                <div className="cm-wd-20">
                  <span className="offer-tag">Offer</span>
                  <span className="curr__Icon">$</span>
                </div>
                <div className="cm-wd-80">
                  <h5>Sign Up and Save Up to <span>$15 off◊</span></h5>
                  <p>our fees on selected regions with a promo code!</p>
                  <div className="promo-code-form">
                    <form action="">
                      <input type="text" placeholder="Enter email address" />
                      <input type="submit" value="Get Promo Code!" />
                    </form>
                  </div>
                  <p><small>Expires May 31, 2023. Restrictions apply</small></p>
                </div>
              </div>
            </div>
            <div className="cm-wd-50">
              <div className="column cm-flex cm-flex-wrap">
                <div className="cm-wd-20">
                  <span className="offer-tag">Call Now</span>
                  <span className="curr__Icon"><i className="fa-solid fa-phone"></i></span>
                  {/* <div className="book-mob">
                      <img src={callMob} alt="" />
                    </div> */}
                </div>
                <div className="just-booking cm-wd-80">
                  <h5>Why to book with Travel Expert</h5>
                  <ul>
                    <li>Expert guidance by our Travel experts</li>
                    <li>Immediate booking confirmation</li>
                    <li>24 hour cancellation</li>
                    <li>Plans Flexible payment</li>
                  </ul>
                  <div className="booking-call">
                    <a href={`tel:${phoneNum.label}`} className="cm-btn">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service_section">
        <h2>Here's why travelers choose FlightHubDeal</h2>
        <div className="cm-page-center cm-flex cm-flex-wrap">
          <div className="cm-wd-25">
            <i className="fa fa-get-pocket" aria-hidden="true"></i>
            <h5>Get Great Deals!</h5>
            <p>Choose from 500+ airlines for low airfares!</p>
          </div>
          <div className="cm-wd-25">
            <i className="fa fa-mars" aria-hidden="true"></i>
            <h5>Price Match Promise</h5>
            <p>Find low prices to destinations worldwide.</p>
          </div>
          <div className="cm-wd-25">
            <i className="fa fa-ban" aria-hidden="true"></i>
            <h5>Easy Cancellations with FlightHubDeal</h5>
            <p>Convenient self-service options available online.</p>
          </div>
          <div className="cm-wd-25">
            <i className="fa fa-envira" aria-hidden="true"></i>
            <h5>Expert Guidance</h5>
            <p>Get personalized assistance from our travel experts.</p>
          </div>
        </div>
      </div>

      {loadBestTour()}

      <div className="cm-section review-section">
        <div className="cm-page-center">
          <h2 className="cm-txt-center"> Customer Reviews </h2>

          <Slider {...settings} className="cm-home-guide-slider">
            <div className="cm-home-guide-slide">
              <p>Flighthubdeal created an incredible tour for my family and relatives employing pocket-friendly last-minute travel flights.</p>
              {/* <strong>Customer Name</strong>   */}
            </div>
            <div className="cm-home-guide-slide">
              <p>Wonderful time with you all! The service was really helpful in aiding me with all aspects of my flight change procedures.</p>
              {/* <strong>Customer Name</strong> */}
            </div>
          </Slider>
        </div>
      </div>

    </>

  );
};

export default HomePage;
