import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Header.styles.css";
import logo from "../../assets/images/flight/logo-new.png";
import callNow from "../../assets/images/flight/call-now.png";
import support from "../../assets/images/flight/support (1).png";
import direction from "../../assets/images/flight/direction.png";
import { phoneNum } from "../../utils/globalVars";
import CountrySelector from "../countrySeloctor/CountrySelector";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [third, setThird] = React.useState(false);
  const [active, setactive] = React.useState('Home');

  const [toggle, setToggle] = React.useState(false);

  const width = window.width <= 767;

  const handletoggle = () => {
    toggle === true ? setToggle(false) : setToggle(true);
  };

  const handleclick = () => {
    setOpen(true);
    setShow(false);

  };
  const handleclick2 = () => {
    setShow(true);
    setOpen(false);

  };

  const handleclick3 = () => {
    setThird(true);
  };


  const activeMenuLink = (active) => {
    setactive(active);
  }


  const handleMenuItem = () => {
    setToggle(false);
    setOpen(false);
    setShow(false);
  };


  return (
    <header>
      <div className="cm-page-center cm-flex-type-1 header-top">
        <div className="cm-header-logo">
          <Link to="/">
            <img src={logo} alt="Trian Fly" />
          </Link>
        </div>
        <div className="cm-header-op cm-flex cm-flex-align-mid">
          <div className="cm-sec-menu">
            <ul className="cm-menu-ul cm-flex-type-1">
              <li className="cm-header-phone">
                <img src={callNow} alt="Trian Fly" />
                <a href="tel:+18557381940">(855) 738-1940</a>
                <span>Speak to a travel expert</span>
              </li>
              {/* <li>
                <CountrySelector />
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-menu cm-page-center">
        <div className="mobile-menu-top">
          <div className="cm-header-logo">
            <Link to="/">
              <img src={logo} alt="Trian Fly" />
            </Link>
          </div>
          <div className="cm-expert">
            <div>
              <img src={callNow} alt="Trian Fly" />
            </div>
            <div>
              <a href={`tel:${phoneNum.label}`}>{phoneNum.label}</a>
              <p>Speak to a travel expert</p>
            </div>
          </div>
        </div>
        {/* <div className="mobile-menu-bottom">
          <div>
            <CountrySelector />
          </div>
          <div className="toggle" onClick={handletoggle}>
            <i class="fa-solid fa-bars"></i>
          </div>
        </div> */}
      </div>
      {/* <div className={`cm-page-center header-bottom navBarmenu menu ${toggle === false ? `` : `active`
        }`}
      >
        <ul className="bottom-nav">
          <li className={(active === 'Home') ? `active` : ''}>
            <i class="fa-solid fa-house fa-icons"></i>
            <Link onClick={() => activeMenuLink("Home")} to="/">
              Home
            </Link>
          </li>
          <li onMouseOver={handleclick} onMouseLeave={() => setOpen(false)} className={`airlines-dropdown ${(active === 'Popular Airlines') ? `active airlines-dropdown` : ``}`}>
            <i class="fa-solid fa-plane"></i>
            <Link onClick={() => activeMenuLink("Popular Airlines")} to="/popular-airlines">
              Popular Airlines{" "}
              {!open ? (
                <i class="fa-solid fa-chevron-down"></i>
              ) : (
                <i
                  onClick={() => setOpen(false)}
                  class="fa-solid fa-chevron-up"
                ></i>
              )}
            </Link>
            <ul style={{ display: `${open ? "" : "none"}` }}>
              <li>
                <Link onClick={handleMenuItem} to="/delta-airlines">
                  Delta Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/united-airlines">
                  United Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/alaska-airlines">
                  Alaska Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/aeromexico-airlines">
                  Aeromexico Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/copa-airlines">
                  Copa Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/virgin-atlantic">
                  Virgin Atlantic
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/iberia-airlines">
                  Iberia Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/royal-jordanian-airlines">
                  Royal Jordan
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/british-airways">
                  British Airways
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/ryan-air">
                  Ryan Air
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/air-france">
                  Air France
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/klm-airlines">
                  KLM Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/singapore-airlines">
                  Singapore Airlines
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/">
                  LOT Polish Airlines
                </Link>
              </li>
            </ul>
          </li>
          <li onMouseOver={handleclick2} onMouseLeave={() => setShow(false)} className={`airlines-dropdown ${(active === 'Top Destination') ? `active airlines-dropdown` : ``}`}>
            <i class="fa-solid fa-location-dot"></i>
            <Link onClick={() => activeMenuLink("Top Destination")} to="top-destinations">
              Top Destinations{" "}
              {!show ? (
                <i class="fa-solid fa-chevron-down"></i>
              ) : (
                <i
                  onClick={() => setShow(false)}
                  class="fa-solid fa-chevron-up"
                ></i>
              )}
            </Link>
            <ul style={{ display: `${show ? "" : "none"}` }}>
              <li>
                <Link onClick={handleMenuItem} to="/las-vegas">
                  Las Vegas
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/cancun">
                  Cancun
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/goa">
                  Goa
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/chicago">
                  Chicago
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/miami">
                  Miami
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/bangkok">
                  Bangkok
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/europe">
                  Europe
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/london">
                  London
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/new-york">
                  New York
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/paris">
                  Paris
                </Link>
              </li>
              <li>
                <Link onClick={handleMenuItem} to="/toronto">
                  Toronto
                </Link>
              </li>
            </ul>
          </li>
          <li onMouseOver={handleclick3} onMouseLeave={() => setThird(false)} className={`airlines-dropdown ${(active === 'Name Change') ? `active airlines-dropdown` : ``}`}>
            <i class="fa-solid fa-user"></i>
            <Link onClick={() => activeMenuLink("Popular Airlines")} to="/popular-airlines">
              Name Change{" "}
              {!third ? (
                <i class="fa-solid fa-chevron-down"></i>
              ) : (
                <i
                  onClick={() => setThird(false)}
                  class="fa-solid fa-chevron-up"
                ></i>
              )}
            </Link>
            <ul style={{ display: `${third ? "" : "none"}` }}>
              <li>
                <Link onClick={handleMenuItem} to="/united-airlines-name-change">
                  United Airlines
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${(active === 'About Us') ? `active airlines-dropdown` : ``}`}>
            <i class="fa-solid fa-address-card"></i>
            <Link onClick={() => activeMenuLink("About Us")} to="/about-us">
              About us
            </Link>
          </li>
          <li className={`${(active === 'Contact Us') ? `active airlines-dropdown` : ``}`}>
            <i class="fa-solid fa-phone"></i>
            <Link onClick={() => activeMenuLink("Contact Us")} to="/contact-us">
              Contact Us
            </Link>
          </li>
        </ul>
      </div> */}
    </header>
  );
};

export default Header;
