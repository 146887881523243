import React from 'react'
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

import './top-airlines.style.css'
import BannerSearchForm from '../../components/flight-search-form/BannerSearchForm.component'
import FlightSearchForm from '../../components/flight-search-form/FlightSearchForm.component'

export const AlaskaAirlines = () => {
    return (
        <>
         <div className="home-banner">
            <div className="cm-page-center">
            <div className="search-banner">
                <h1>Let FlightHubDeal bring your dream vacation to new heights.</h1>
                <FlightSearchForm />
            </div>
            </div>
        </div>
        <div className="cm-section cm-page-center">
            {/* <BannerSearchForm depVal={data} urlVal={dataToSend} /> */}
            <div className="ryan-air">
                <div className="ryanair-title">
                    <h1>Alaska <span>Airlines</span></h1>
                </div>
                <div className="ryanair-content">
                    <p>
                        Alaska is a large airline from the united states headquartered in SeaTac, Washington, within the Seattle Metropolitan area. In terms of the number of regularly scheduled passengers carried, it is North America's fifth-largest airline. Alaska has a route network largely focused on linking cities along the west coast of the United States to more than 100 destinations in the contiguous United States, Belize, Canada, Guatemala, Costa Rica, and Mexico. Also, the airline operates this network with its regional partners SkyWest and horizon airlines.
                    </p>
                    <p>
                        The airline runs out of five hubs, with Seattle-Tacoma International Airport serving as its main base. The third-largest airline alliance in the world, Oneworld, also counts Alaska as a member. Alaska Airlines will have more than 16,000 employees by 2020, and J.D. Power and Associates has consistently rated them as having the highest customer satisfaction among traditional airlines for the past twelve years. All Alaska Airlines run a mainline fleet consisting primarily of Boeing 737 series aircraft, With the exception of a small number of Airbus A321neo aircraft that Virgin America bought but didn't receive until after the merger.
                    </p>
                    <p>
                        All Alaska jets are equipped with an in-flight WiFi and streaming entertainment system. It had been announced that the cost of internet access would shortly increase to a flat $8 fee for each flight, but only for aircraft equipped with satellite WiFi. On the older internet, WiFi service is still fee-based for all passengers, depending on the time of the flight. Additionally, streaming entertainment and electronic messaging services are free in Alaska.
                    </p>
                    <p>
                        Additionally, Alaska's first-class cabin features priority boarding, complimentary food, and alcoholic and non-alcoholic beverages. Plus, seating it wide recliner-style seats in a 2-2 configuration on mainline aircraft and a 2-1 configuration on regional jets. The Alaska premium class is located behind first class and features 35 inches of seat pitch, and it provides complimentary alcoholic and non-alcoholic beverages. Passengers of the main cabin can get a complimentary non-alcoholic beverage and a small snack. On February 2012, Alaska started serving coffee from fellow seattle company Starbucks on all its flights, and in July 2018, Alaska updated much of the first-class menu inspired West coast presence.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}
